import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/Template.js";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import Divider from 'src/components/Divider';
import Chevron from 'src/assets/svgs/chevron-down.svg';
import AnalyticsScript from 'src/components/Analytics';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Join Our Team`}</h1>
    <hr></hr>
    <p>{`Want to join this winning team? We are looking for devoted, talented creators to join our team! We have positions open for Writers, Designers, and Illustrators. Please feel free to apply even if your background is unconventional.`}</p>
    <p>{`As a Meme Insider staff member, you should be comfortable with the content produced under Meme Insider being edited and used freely.`}</p>
    <Divider mdxType="Divider" />
    <Accordion allowMultipleExpanded allowZeroExpanded mdxType="Accordion">
      <h2>{`Writing Positions`}</h2>
      <p>{`The true content of Meme Insider. Our core writers are expected to be an "insider expert" of several aspects of web culture, whether that be, for example, a deep understanding of a platform such as Instagram or Twitter, or a strong working knowledge of internet history. If this sounds like you, then we would strongly encourage you to apply to become one of our expert core writers.`}</p>
      <p><em parentName="p">{`Core Writers for the magazine are paid a flat rate per article published.`}</em></p>
      <AccordionItem mdxType="AccordionItem">
  <AccordionItemHeading mdxType="AccordionItemHeading">
    <AccordionItemButton mdxType="AccordionItemButton">
      <h3 className="accordion-heading">
        Core Writer
        <Chevron mdxType="Chevron" />
      </h3>
    </AccordionItemButton>
  </AccordionItemHeading>
  <AccordionItemPanel mdxType="AccordionItemPanel">
          <p><em parentName="p"><strong parentName="em">{`RESPONSIBILITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Produce one to two fully-edited written magazine articles each month before established deadlines.`}</li>
            <li parentName="ul">{`Actively engage and collaborate with a remote team of other writers.`}</li>
            <li parentName="ul">{`Promptly respond to suggestions and corrections from editors.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`ABILITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Strong writing skills, organization skills, and work ethic.`}</li>
            <li parentName="ul">{`Deep (beyond average) knowledge of at least two to three aspects of web culture.`}</li>
            <li parentName="ul">{`Writing in an objective, unopinionated manner, upholding values of journalistic ethics and integrity. `}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`REQUIREMENTS`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Demonstrated strong writing ability.`}</li>
            <li parentName="ul">{`Deep knowledge of some aspect of web culture.`}</li>
            <li parentName="ul">{`Age 18 or older.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`BONUS QUALITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Familiarity with the AP style guide.`}</li>
            <li parentName="ul">{`Experience writing web content.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`HOW TO APPLY`}</strong></em><br />{`
Email `}<a parentName="p" {...{
              "href": "mailto:opportunities@memeinsider.com"
            }}>{`opportunities@memeinsider.com`}</a>{` with your name, resume, cover letter, writing samples, and a sample Meme Insider article on a topic of your choosing.`}</p>
  </AccordionItemPanel>
      </AccordionItem>
      <Divider mdxType="Divider" />
      <h2>{`Design Team Positions`}</h2>
      <p>{`A lot of the magic of Meme Insider comes from our incredible design team. You can apply for either our core design team, or our illustration team to create artwork each month for us to use in the magazine, posters, stickers, or other venues.`}</p>
      <p><em parentName="p">{`Designers are paid a flat rate per article layout produced.`}</em></p>
      <p><em parentName="p">{`Illustrators are paid a flat rate per illustrated article.`}</em></p>
      <AccordionItem mdxType="AccordionItem">
  <AccordionItemHeading mdxType="AccordionItemHeading">
    <AccordionItemButton mdxType="AccordionItemButton">
      <h3 className="accordion-heading">
        Designer
        <Chevron mdxType="Chevron" />
      </h3>
    </AccordionItemButton>
  </AccordionItemHeading>
  <AccordionItemPanel mdxType="AccordionItemPanel">
          <p><em parentName="p"><strong parentName="em">{`RESPONSIBILITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Produce one to two magazine article layouts each month.`}</li>
            <li parentName="ul">{`Work on graphic design projects, such as logo design or Photoshop work.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`ABILITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Strong work ethic and active communication over Discord.`}</li>
            <li parentName="ul">{`Working independently without strong instruction.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`REQUIREMENTS`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Demonstrated knowledge of Adobe Indesign.`}</li>
            <li parentName="ul">{`General knowledge of Photoshop (or equivalent such as GIMP and Procreate) and Illustrator (or Affinity Designer).`}</li>
            <li parentName="ul">{`Graphic design interest.`}</li>
            <li parentName="ul">{`Age 18 or older.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`BONUS QUALITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Print technique knowledge.`}</li>
            <li parentName="ul">{`Advanced knowledge of layout and grid systems.`}</li>
            <li parentName="ul">{`Ability to create original artwork.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`HOW TO APPLY`}</strong></em><br />{`
Email `}<a parentName="p" {...{
              "href": "mailto:opportunities@memeinsider.com"
            }}>{`opportunities@memeinsider.com`}</a>{` with your Name, Resume, Cover Letter, Samples of Design Work, and a Redesigned Meme Insider Article from the `}<a parentName="p" {...{
              "href": "https://drive.google.com/open?id=1r8DV2jOn5Xgr_3WAOSey2Su_a0sUcOzn"
            }}>{`past 6 months`}</a>{`.`}</p>
  </AccordionItemPanel>
      </AccordionItem>
      <AccordionItem mdxType="AccordionItem">
  <AccordionItemHeading mdxType="AccordionItemHeading">
    <AccordionItemButton mdxType="AccordionItemButton">
      <h3 className="accordion-heading">
        Illustrator
        <Chevron mdxType="Chevron" />
      </h3>
    </AccordionItemButton>
  </AccordionItemHeading>
  <AccordionItemPanel mdxType="AccordionItemPanel">
          <p><em parentName="p"><strong parentName="em">{`RESPONSIBILITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Create illustrations for print articles.`}</li>
            <li parentName="ul">{`Create artwork for other Meme Insider publications.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`ABILITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Strong work ethic and active communication over Discord.`}</li>
            <li parentName="ul">{`Work quickly with tight deadlines (4 day turn-around).`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`REQUIREMENTS`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Demonstrated knowledge of any illustration program (eg. Photoshop, Illustrator, Procreate, Affinity Designer, etc.).`}</li>
            <li parentName="ul">{`Demonstrated artistic skill in digital mediums.`}</li>
            <li parentName="ul">{`Age 18 or older.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`BONUS QUALITIES`}</strong></em><br /></p>
          <ul>
            <li parentName="ul">{`Able to work tightly within specific colors and art styles.`}</li>
            <li parentName="ul">{`Versatile art style.`}</li>
          </ul>
          <p><em parentName="p"><strong parentName="em">{`HOW TO APPLY`}</strong></em><br />{`
Please email `}<a parentName="p" {...{
              "href": "mailto:opportunities@memeinsider.com"
            }}>{`opportunities@memeinsider.com`}</a>{` with your name, resume, cover letter, art samples, and a custom illustration from a Meme Insider article from the `}<a parentName="p" {...{
              "href": "https://drive.google.com/open?id=1r8DV2jOn5Xgr_3WAOSey2Su_a0sUcOzn"
            }}>{`past 6 months`}</a>{`.`}</p>
  </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
    <AnalyticsScript mdxType="AnalyticsScript" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      